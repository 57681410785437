
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_manage.component_organization') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" sm="6">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="org_type"
                >
                  <template v-slot:label>
                    {{$t('research_manage.organization_type')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.org_type"
                      :options="organizationType"
                      id="org_type"
                    >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="6" sm="6" v-if="search.org_type == 1">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="org_id"
                >
                  <template v-slot:label>
                    {{$t('elearning_config.organization')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.org_id"
                      :options="orgList"
                      id="org_id"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
            </b-col>
            <b-col v-if="search.org_type == 2" lg="6" md="6" sm="12" xs="12">
              <b-form-group
                  class="row"
                  label-cols-sm="5"
                  :label="$t('research_manage.component_organization')"
                  label-for="name"
              >
                  <b-form-input
                      id="name"
                      v-model="search.org_name"
                      placeholder=""
                  ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="2" md="2" sm="12" xs="12">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
            </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.component_organization_info') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="component-organization-form" :class="'btn btn_add_new'">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(coord_mobile_no)="data">
                      <span class="">{{ $n('0') + $n(data.item.coord_mobile_no, { useGrouping: false }) }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      <span v-if="data.item.status == 1" class="badge badge-success">{{ $t('globalTrans.active') }}</span>
                      <span v-else class="badge badge-danger">{{ $t('globalTrans.inactive') }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                     <router-link title="Edit" @click="edit(data.item)" :to="`/agri-research-service/research-management/configuration/component-organization-form?id=${data.item.id}`" class="btn btn-sm btn-warning mr-1"><i class="ri-ball-pen-fill m-0"></i></router-link>
                     <b-button v-b-modal.modal-detail title="Details" @click="details(data.item)" class="btn btn-sm btn-success"><i class="ri-eye-line m-0 "></i></b-button>
                     <b-button title="Active/Inactive" :variant="data.item.status == 2 ? ' iq-bg-danger' : ' iq-bg-success'" size="sm" @click="remove(data.item)">
                        <i class="fas" :class="data.item.status == 2 ? 'fa-toggle-off' : 'fa-toggle-on'"></i>
                      </b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
    <b-modal id="modal-detail" size="lg" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <DetailModal :id="editItemId" :key="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>
import DetailModal from './Detail.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { componentOrganizationList, componentOrganizationToggleStatus } from '../../../api/routes'
import RouteBasedList from '@/mixins/route-base-list'
import { mapGetters } from 'vuex'
import { core } from '@/config/pluginInit'

export default {
  mixins: [RouteBasedList],
  components: {
    DetailModal
  },
  data () {
    return {
        search: {
          org_type: 0,
          org_name: '',
          org_id: 0
        },
        userList: [],
        editItemId: 0
    }
  },
  watch: {
  },
  computed: {
      organizationType: function () {
        return this.$store.state.AgriResearch.commonObj.organizationType
      },
      orgList: function () {
        return this.$store.state.orgList.filter(item => item.status === 0)
      },
      ...mapGetters({
          authUser: 'Auth/authUser'
      }),
      formTitle () {
          return (this.editItemId === 0) ? this.$t('research_manage.component_organization_info') + ' ' + this.$t('globalTrans.entry') : this.$t('research_manage.component_organization_info') + ' ' + this.$t('globalTrans.modify')
      },
      detailTitle () {
          return this.$t('research_manage.component_organization') + ' ' + this.$t('globalTrans.details')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('research_manage.organization_type'), class: 'text-left' },
              { label: this.$t('research_manage.org_or_univ_name'), class: 'text-left' },
              { label: this.$t('research_manage.coordinator_name'), class: 'text-center' },
              { label: this.$t('research_manage.coordinator_designation'), class: 'text-left' },
              { label: this.$t('research_manage.coordinator_mobile'), class: 'text-left' },
              { label: this.$t('globalTrans.status'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
                  { key: 'index' },
                  { key: 'org_type_bn' },
                  { key: 'component_org_name_bn' },
                  { key: 'coord_name_bn' },
                  { key: 'coord_designation_bn' },
                  { key: 'coord_mobile_no' },
                  { key: 'status' },
                  { key: 'action' }
              ]
          } else {
              keys = [
                  { key: 'index' },
                  { key: 'org_type_en' },
                  { key: 'component_org_name' },
                  { key: 'coord_name' },
                  { key: 'coord_designation' },
                  { key: 'coord_mobile_no' },
                  { key: 'status' },
                  { key: 'action' }
              ]
          }
          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search)
    }
    this.loadData()
  },
  methods: {
    details (item) {
      this.editItemId = item.id
    },
    edit (item) {
      this.editItemId = item.id
    },
    async searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(agriResearchServiceBaseUrl, componentOrganizationToggleStatus, item, 'agri_research', 'componentOrgList')
    },
    loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, componentOrganizationList, params).then(response => {
          if (response.success) {
            this.userList = response.userList
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
          const organizationTypeObj = this.$store.state.AgriResearch.commonObj.organizationType.find(doc => doc.value === parseInt(item.org_type))
          const newData = {
            org_type_en: organizationTypeObj !== undefined ? organizationTypeObj.text_en : '',
            org_type_bn: organizationTypeObj !== undefined ? organizationTypeObj.text_bn : ''
          }
          if (item.org_type === 1) {
            const tmpUser = this.userList.find(user => user.value === item.user_id)
            const tmpOrgProfile = this.$store.state.orgList.find(org => org.value === item.org_id)
            const tmpDesignation = this.$store.state.commonObj.designationList.find(org => org.value === item.designation_id)

            newData.component_org_name = tmpOrgProfile !== undefined ? tmpOrgProfile.text_en : ''
            newData.component_org_name_bn = tmpOrgProfile !== undefined ? tmpOrgProfile.text_bn : ''
            newData.coord_name = tmpUser !== undefined ? tmpUser.text_en : ''
            newData.coord_name_bn = tmpUser !== undefined ? tmpUser.text_bn : ''
            if (newData.coord_name_bn === 'অজানা নাম') {
              newData.coord_name_bn = ''
            }
            newData.coord_designation = tmpDesignation !== undefined ? tmpDesignation.text_en : ''
            newData.coord_designation_bn = tmpDesignation !== undefined ? tmpDesignation.text_bn : ''
          }
          return Object.assign({}, item, newData)
      })
      return listData
    }
  }
}
</script>
